







import { Component, Vue } from "vue-property-decorator";
import CSSModule from "@/store/modules/css-module";
import "material-design-icons/iconfont/material-icons.css";
import CargaDialog from "@/views/cargando/CargaDialog.vue";
@Component({
  components: {
    CargaDialog,
  },
})
export default class MainPrintingLayout extends Vue {
  public created() {
    this.bodystyle();
    Vue.prototype.$heightsection = this.$vuetify.breakpoint.height - 110;
  }

  public async bodystyle() {
    if ((process.env.VUE_APP_PRODUCTION as string) === "true") {
      if (this.$vuetify.breakpoint.smAndDown) {
        document.body.style.paddingRight = "0px";
      } else {
        document.body.style.overflowX = "visible";
        document.body.style.paddingRight = "0px";
      }
    }
    if (!CSSModule.cssimportados) {
      var files: string[] = [];
      files.push(
        "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css"
      );
      for (let i = 0; i < files.length; i++) {
        var newSS = document.createElement("link");
        newSS.rel = "stylesheet";
        newSS.href = files[i]; //'data:text/css,' + escape(styles);
        document.getElementsByTagName("head")[0].appendChild(newSS);
      }

      CSSModule.css_importados();
    }
  }
}
